<template>
    <Modal
      :title="isReject ? '심사를 반려하시겠습니까?' : '심사를 보류하시겠습니까?'"
      :isDialog="true"
      width="600px"
    >
      <control>
        <template v-slot:label>
          <template v-if="isReject"> 심사 반려 사유 </template>
          <template v-else> 심사 보류 사유 </template>
        </template>
        <Dropdown
          v-model="presetReason"
          placeholder="선택"
          :list="presetReasonsArr"
          :stretch="true"
        />
      </control>
      <control v-if="presetReason === 'others'">
        <template v-slot:label>직접 입력</template>
        <Textarea
          v-model="reason"
          class="input ta reason"
          placeholder="카테고리명을 입력해주세요."
          :maxLen="120"
          :minHeight="200"
        />
      </control>
      <control>
        <template v-slot:label>
          <span>운영자 메모</span>
        </template>
        <Textarea
          v-model="memo"
          class="input ta memo"
          :maxLen="500"
          placeholder="운영자 메모를 작성해주세요. (500자 제한)"
          :minHeight="200"
        />
      </control>
    </Modal>
</template>

<script>
import ModalFormControl from "@/components/modal/ModalFormControl";
import presetReasons from "@/views/contents/main/BlockReasons";

import { Dropdown, Textarea, Modal } from "@ogqcorp/v-ui";

export default {
  data() {
    return {
      presetReason: false,
      presetReasons,
      reason: "",
      memo: "",
    };
  },
  props: ["isReject", "contentType"],
  watch: {
    presetReason(n) {
      console.log(n);
      if (n === "others" || !n) {
        this.reason = "";
      } else {
        const preset = this.presetReasonsArr.find((p) => p.value === n);
        if (preset) {
          this.reason = preset.label;
        } else {
          this.reason = "";
        }
      }
    },
  },
  methods: {
    clear() {
      this.presetReason = false;
      this.reason = "";
      this.memo = "";
    },
  },
  computed: {
    presetReasonsArr() {
      if (this.contentType) {
        return this.presetReasons.filter(
          (p) => p.contentType === this.contentType || p.contentType == null
        );
      } else {
        return this.presetReasons.filter((p) => p.contentType == null);
      }
    },
  },
  components: {
    control: ModalFormControl,
    Modal,
    Textarea,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.input.ta:deep(textarea) {
  min-height: 200px;
}
</style>
